import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state

export interface IPlace {
    address: string;
    city_id: number;
    customer_id: number;
    display_order: number;
    id: number;
    image_path: number;
    is_highlight: boolean;
    latitude: number;
    longitude: number;
    name: string;
    neighborhood_id: number;
    phone: string;
    place_type_id: number;
    postal_code: string;
    price_avg: number;
    published: boolean;
    show: boolean;
    site_url: string;
    uid: string;
}

export interface PlaceState {
    placeSources: IPlace[];
}

// // Define the initial state using that type
const initialStat: PlaceState = {
    placeSources: [],
};

const PlaceSlice = createSlice({
    name: "Places",
    initialState: initialStat,
    reducers: {
        AddListPlaces: {
            reducer: (state, action: PayloadAction<IPlace[]>) => {
                return {
                    placeSources: action.payload,
                };
            },
            prepare: (data: IPlace[]) => {
                return {
                    payload: data,
                };
            },
        },
    },
});

export const { AddListPlaces } = PlaceSlice.actions;

export default PlaceSlice.reducer;
