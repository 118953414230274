import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "src/redux/hooks";
import { IUser } from "src/redux/slices/user";

interface Props {
    children: JSX.Element;
}

const RequireAuth: React.FC<Props> = (props) => {
    const user: IUser | undefined = useAppSelector((state) => state.user.user);
    const location = useLocation();

    if (user === undefined || !user) {
        return <Navigate to="/signin" state={{ from: location }} replace />;
    }
    return props.children;
};

export default RequireAuth;
