import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface ICity {
    city: any;
}

export interface CitiesState {
    citiesSources?: ICity[];
}

// // Define the initial state using that type
const initialStat: CitiesState = {
    citiesSources: [],
};

const CitiesSlice = createSlice({
    name: "cities",
    initialState: initialStat,
    reducers: {
        AddListCities: {
            reducer: (state, action: PayloadAction<ICity[]>) => {
                return {
                    citiesSources: action.payload,
                };
            },
            prepare: (data: ICity[]) => {
                return {
                    payload: data,
                };
            },
        },
    },
});

export const { AddListCities } = CitiesSlice.actions;

export default CitiesSlice.reducer;
