import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state

export interface IMealType {
    id: number;
    name: string;
    uid: string;
    place_id?: number | null;
    show: boolean;
    published: number;
}

export interface MealTypeState {
    mealTypeSources: IMealType[];
}

// // Define the initial state using that type
const initialStat: MealTypeState = {
    mealTypeSources: [],
};

const mealTypeSlice = createSlice({
    name: "mealsTypes",
    initialState: initialStat,
    reducers: {
        AddListMealTypes: {
            reducer: (state, action: PayloadAction<IMealType[]>) => {
                return {
                    mealTypeSources: action.payload,
                };
            },
            prepare: (data: IMealType[]) => {
                return {
                    payload: data,
                };
            },
        },
    },
});

export const { AddListMealTypes } = mealTypeSlice.actions;

export default mealTypeSlice.reducer;
