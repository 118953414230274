const FACEBOOK_APP_ID = "1721625711645260";

export default function initFacebookSDK(): any {
    return new Promise<void>((resolve) => {
        // Wait for the Facebook SDK to initialize before starting the React app.
        window.fbAsyncInit = function initial() {
            window.FB.init({
                appId: FACEBOOK_APP_ID,
                xfbml: true,
                version: "v18.0",
            });
            window.FB.AppEvents.logPageView();
            resolve();
        };
        // Load the Facebook SDK script.
        (function (d, s, id) {
            let js,
                fjs = d.getElementsByTagName(s)[0] as any;
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s) as any;
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    });
}
