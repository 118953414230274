import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state

export interface IUser {
    id: number;
    name: string;
    first_name?: string;
    last_name?: string;
    avartar?: string;
    email: string;
    profile?: {
        id: number;
        user_id: number;
        gender?: string;
        country_id?: {
            id: number;
            name: string;
            iso_code: string;
            currency_code: string;
            iso_numeric: string;
            north: number;
            south: number;
            east: number;
            west: number;
            capital_name: string;
            continent_code: string;
            iso_alpha: string;
            geo_name_id: string;
            display_order: number;
        };
        mobile_number?: string;
        birthdate?: string;
    };
    customer_id?: number;
    customer?: {
        id: number;
        uid: string;
        name: string;
        address?: string;
        phone?: string;
        website?: string;
        notes?: string;
        published: boolean;
        customer_type_id?: number;
        places: Array<{
            id: number;
            uid: string;
            name: string;
            image_path: string;
            address?: string;
            postal_code?: string;
            phone?: string;
            price_avg?: number;
            site_url?: string;
            latitude?: number;
            longitude?: number;
            display_order?: number;
            place_type_id?: number;
            city_id?: number;
            neighborhood_id?: number;
            is_highlight: boolean;
            show: boolean;
            published: boolean;
            customer_id?: number;
        }>;
    };
}

export interface UserState {
    user?: IUser;
}

// // Define the initial state using that type
const initialStat: UserState = {
    user: undefined,
};

const userSlice = createSlice({
    name: "user",
    initialState: initialStat,
    reducers: {
        AddUser: {
            reducer: (state, action: PayloadAction<IUser>) => {
                return {
                    user: action.payload,
                };
            },
            prepare: (data: IUser) => {
                return {
                    payload: data,
                };
            },
        },
        deleteUser: {
            reducer: () => {
                return {
                    user: undefined,
                };
            },
            prepare: () => {
                return {
                    payload: {},
                };
            },
        },
    },
});

export const { AddUser, deleteUser } = userSlice.actions;

export default userSlice.reducer;
