
import { useEffect, useState } from 'react';

export const CustomToast = ({ appearance, children }: any) => {

    const [claseAnimation, setClaseAnimation] = useState('custom-toast-enter');

    useEffect(() => {
        setTimeout(() => {
            setClaseAnimation('custom-toast-exit');
        }, 4000);
    }, []);

    return (

        <div
            className={`custom-toast ${claseAnimation} custom-toast-${appearance}`}>
            {appearance === 'success' && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    width="24"
                    height="24"
                    strokeLinejoin="round"
                    className="feather feather-check-circle">
                    <polyline points="20 6 9 17 4 12" />
                </svg>
            )}
            {appearance === 'error' && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    width="24"
                    height="24"
                    className="feather feather-x-circle">
                    <circle cx="12" cy="12" r="10" />
                    <line x1="15" y1="9" x2="9" y2="15" />
                    <line x1="9" y1="9" x2="15" y2="15" />
                </svg>
            )}
            {appearance === 'warning' && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    width="24"
                    height="24"
                    strokeLinejoin="round"
                    className="feather feather-alert-triangle">
                    <path d="M12 9v2m0 4v.01" />
                    <circle cx="12" cy="12" r="10" />
                </svg>
            )
            }
            {
                appearance === 'info' && (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        width="24"
                        height="24"
                        className="feather feather-info">
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="16" x2="12" y2="12" />
                        <line x1="12" y1="8" x2="12" y2="8" />
                    </svg>
                )
            }
            <span style={{ marginLeft: '10px' }}>{children}</span>
        </div>
    );
};
