import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ThemeProvider from "./redux/providers/theme-provider";
import PersistProvider from "./redux/providers/persist-provider";
import { store } from "./redux/store";
import App from "./App";

import initFacebookSDK from "./helpers/initFacebookSDK";
import { ToastProvider } from "react-toast-notifications";
import { CustomToast } from "./containers/apps/components/CustomToast";
// import CustomToast from './CustomToast';

const renderApp = () => {
    ReactDOM.render(
        <Provider store={store}>
            <PersistProvider>
                <ToastProvider components={{ Toast: CustomToast }} autoDismiss  >
                    <ThemeProvider>
                        <App />
                    </ThemeProvider>
                </ToastProvider>
            </PersistProvider>
        </Provider>,
        document.getElementById("root")
    );
};

initFacebookSDK().then(() => {
    renderApp();
});