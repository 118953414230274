import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export interface IScarousel {
    id: string;
    image: string;
}

export interface CarouselState {
    carouselSources: IScarousel[];
}

// Define the initial state using that type
const initialState: CarouselState = {
    carouselSources: [],
};

const carouselSlice = createSlice({
    name: "carousel",
    initialState,
    reducers: {
        addImage: {
            reducer: (state, action: PayloadAction<IScarousel>) => {
                state.carouselSources.push(action.payload);
            },
            prepare: (value: string) => {
                const data = {
                    id: uuidv4(),
                    image: value,
                };
                return { payload: data };
            },
        },
        deleteImage: (state, action: PayloadAction<IScarousel>) => {
            const {
                payload: { id },
            } = action;

            const index = state.carouselSources.findIndex((el) => el.id === id);
            if (index !== undefined && index >= 0) {
                state.carouselSources.splice(index, 1);
            }
        },
        reset: () => {
            return {
                carouselSources: [],
            };
        },
    },
});

export const { addImage, deleteImage, reset } = carouselSlice.actions;

export default carouselSlice.reducer;
