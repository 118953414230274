import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state

export interface IAuth {
    access_token?: string;
    token_type?: string;
}

export interface AuthState {
    credential: IAuth;
}

// // Define the initial state using that type
const initialStat: AuthState = {
    credential: {},
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialStat,
    reducers: {
        AddCredential: {
            reducer: (state, action: PayloadAction<IAuth>) => {
                return {
                    credential: action.payload,
                };
            },
            prepare: (data: IAuth) => {
                return {
                    payload: data,
                };
            },
        },
        deleteCredential: {
            reducer: () => {
                return {
                    credential: {},
                };
            },
            prepare: () => {
                return {
                    payload: {},
                };
            },
        },
    },
});

export const { AddCredential, deleteCredential } = authSlice.actions;

export default authSlice.reducer;
