import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEvent } from "@doar/shared/types";

// Define a type for the slice state

export interface EvenState {
    eventSources: IEvent[];
}

// // Define the initial state using that type
const initialState: EvenState = {
    eventSources: [
        // {
        //     category: "stories",
        //     backgroundColor: "#d9e8ff",
        //     borderColor: "#0168fa",
        //     events: [],
        // },
        {
            category: "Facebook",
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "10",
            events: [],
        },
        {
            category: "Instagram",
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "5",
            events: [],
        },
        {
            category: "Plateful",
            // category: "Pix",  
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "1",
            events: [],
        },
        // birthdayEvents,
        // holidayEvents,
        // discoveredEvents,
        // meetupEvents,
        // otherEvents,
    ],
};

interface IEventState {
    id: string;
    title: string;
    start: string;
    end: string;
    category: string;
    description?: string;
    status?: number;
}

const eventSlice = createSlice({
    name: "events",
    initialState,
    reducers: {
        addListEventPosts: {
            reducer: (state, action: PayloadAction<IEventState[]>) => {
                // const source = state.eventSources.find(
                //     (src) => src.category === "posts"
                // );
                action.payload.map((item: IEventState) => {
                    const source = state.eventSources.find(
                        (src) => src.category === item.category
                    );
                    source?.events.push(item);
                    return item;
                });
            },
            prepare: (data: IEventState[]) => {
                return {
                    payload: data,
                };
            },
        },
        editEventPost: {
            reducer: (state: any, action: PayloadAction<IEventState>): any => {
                state.eventSources.map((item: any) => {
                    if (item.category === action.payload.category) {
                        let index = 0;
                        while (index < item.events.length) {
                            if (item.events[index].id === action.payload.id) {
                                item.events[index] = action.payload; // eslint-disable-line no-param-reassign
                            }
                            index += 1;
                        }
                    }
                    return item;
                });
            },
            prepare: (data: IEventState) => {
                return {
                    payload: data,
                };
            },
        },
        deleteListEventPosts: {
            reducer: () => {
                // const source = state.eventSources.find(
                //     (src) => src.category === "posts"
                // );
                // source?.events = [];
            },
            prepare: () => {
                return {
                    payload: {},
                };
            },
        },
        addListEventStory: {
            reducer: (state, action: PayloadAction<IEventState[]>) => {
                action.payload.map((item: IEventState) => {
                    const source = state.eventSources.find(
                        (src) => src.category === item.category
                    );
                    source?.events.push(item);
                    return item;
                });
            },
            prepare: (data: IEventState[]) => {
                return {
                    payload: data,
                };
            },
        },
        editEventStory: {
            reducer: (state: any, action: PayloadAction<IEventState>) => {
                state.eventSources.map((item: any) => {
                    if (item.category === action.payload.category) {
                        let index = 0;
                        while (index < item.events.length) {
                            if (item.events[index].id === action.payload.id) {
                                item.events[index] = action.payload; // eslint-disable-line no-param-reassign
                            }
                            index += 1;
                        }
                    }
                    return item;
                });
            },
            prepare: (data: IEventState) => {
                return {
                    payload: data,
                };
            },
        },
        deleteEventStory: {
            reducer: () => {
                // const source = state.eventSources.find(
                //     (src) => src.category === "stories"
                // );
                // source?.events = [];
                return {
                    eventSources: [],
                };
            },
            prepare: () => {
                return {
                    payload: {},
                };
            },
        },
        createEvent: {
            reducer: (state: any, action: PayloadAction<IEventState>) => {
                const {
                    payload: { category, ...data },
                } = action;
                const source = state.eventSources.find(
                    (src: any) => src.category === category
                );
// console.log('dataaaaaaaaaaaaaaaaaa:', {category, ...data})
                source?.events.push({category, ...data});
            },
            prepare: (
                id: string,
                title: string,
                startDate: string,
                endDate: string,
                category: string,
                startTime?: string,
                endTime?: string,
                description?: string,
                status?: number
            ) => {
                const start = startTime
                    ? `${startDate}T${startTime}:00`
                    : startDate;
                const end = endTime ? `${endDate}T${endTime}:00` : endDate;

                const data = {
                    id,
                    title,
                    start,
                    end,
                    description,
                    category,
                    status,
                };

                return { payload: { ...data } };
            },
        },
        editEvent: {
            reducer: (state: any, action: PayloadAction<IEventState>) => {
                const {
                    payload: { category, ...data },
                } = action;
                const source = state.eventSources.find(
                    (src: any) => src.category === category
                );
                if (source) {
                    const index = source.events.findIndex(
                        (el: any) => el.id === data.id
                    );
                    if (index !== undefined && index >= 0) {
                        source.events[index] = data;
                    }
                }
            },
            prepare: (
                id,
                title: string,
                startDate: string,
                endDate: string,
                category: string,
                startTime?: string,
                endTime?: string
            ) => {
                const start = startTime
                    ? `${startDate}T${startTime}:00`
                    : startDate;
                const end = endTime ? `${endDate}T${endTime}:00` : endDate;
                const data = {
                    category,
                    id,
                    title,
                    start,
                    end,
                };
                return { payload: { ...data } };
            },
        },
        deleteEvent: (
            state: any,
            action: PayloadAction<{ id: string; category: string }>
        ) => {
            const {
                payload: { id, category },
            } = action;
            const source = state.eventSources.find(
                (src: any) => src.category === category
            );
            if (source) {
                const index = source.events.findIndex(
                    (el: any) => parseInt(el.id, 10) === parseInt(id, 10)
                );
                if (index !== undefined && index >= 0) {
                    source.events.splice(index, 1);
                }
            }
        },
        resetEventsStore : (state) => {
            state.eventSources = [...initialState.eventSources];
        }
    },
});

export const {
    createEvent,
    editEvent,
    editEventPost,
    editEventStory,
    deleteEvent,
    addListEventPosts,
    addListEventStory,
    deleteEventStory,
    deleteListEventPosts,
    resetEventsStore
} = eventSlice.actions;

export default eventSlice.reducer;
