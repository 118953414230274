import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// importar context provider
import LoggedUserProvider from "./context";
import Preloader from "./components/preloader";
import RequireAuth from "./components/common/PrivateRoute";
import "./style.css"
declare global {
    interface Window {
        FB: any;
        fbAsyncInit: any;
    }
}

// Classic Pages

const DashboardTwo = lazy(() => import("./pages/dashboard-two"));
const DashboardThree = lazy(() => import("./pages/dashboard-three"));
const DashboardFour = lazy(() => import("./pages/dashboard-four"));
const VerifyAccount = lazy(() => import("./pages/verify-account"));
const ErrorNotFound = lazy(() => import("./pages/error-404"));
const Error500 = lazy(() => import("./pages/error-500"));
const Error503 = lazy(() => import("./pages/error-503"));
const Error505 = lazy(() => import("./pages/error-505"));
const Connections = lazy(() => import("./pages/connections"));
const Groups = lazy(() => import("./pages/groups"));
const Events = lazy(() => import("./pages/events"));
const Timeline = lazy(() => import("./pages/timeline"));
const Pricing = lazy(() => import("./pages/pricing"));
const HelpCenter = lazy(() => import("./pages/help-center"));
const Invoice = lazy(() => import("./pages/invoice"));
const Chat = lazy(() => import("./pages/apps/chat"));
const Mail = lazy(() => import("./pages/apps/mail"));

// Classic Plus Pages
const ClassicPlusDashboardOne = lazy(
    () => import("./pages/classic-plus/dashboard-one")
);
const ClassicPlusDashboardTwo = lazy(
    () => import("./pages/classic-plus/dashboard-two")
);
const ClassicPlusDashboardThree = lazy(
    () => import("./pages/classic-plus/dashboard-three")
);
const ClassicPlusDashboardFour = lazy(
    () => import("./pages/classic-plus/dashboard-four")
);

const ClassicPlusSignIn = lazy(() => import("./components/login-v2"));
const RegisterV2 = lazy(() => import("./components/register-v2"));
const LandingPage = lazy(() => import("./components/landing-page"));
const ClassicPlusSignUp = lazy(() => import("./pages/classic-plus/signup"));

const ClassicPlusPaymentStripe = lazy(
    () => import("./pages/classic-plus/payment-stripe")
);
const ClassicPlusFreeOrPremium = lazy(
    () => import("./pages/classic-plus/free-premium")
);
const ClassicPlusVerifyAccount = lazy(
    () => import("./pages/classic-plus/verify-account")
);
const ResetPassword = lazy(
    () => import("./pages/classic-plus/reset-password")
);
const ClassicPlusErrorNotFound = lazy(
    () => import("./pages/classic-plus/error-404")
);
const ClassicPlusError500 = lazy(
    () => import("./pages/classic-plus/error-500")
);
const ClassicPlusError503 = lazy(
    () => import("./pages/classic-plus/error-503")
);
const ClassicPlusError505 = lazy(
    () => import("./pages/classic-plus/error-505")
);

const ClassicPlusProfileView = lazy(
    () => import("./pages/classic-plus/profile-view")
);
const ClassicPlusConnections = lazy(
    () => import("./pages/classic-plus/connections")
);
const ClassicPlusGroups = lazy(() => import("./pages/classic-plus/groups"));
const ClassicPlusEvents = lazy(() => import("./pages/classic-plus/events"));
const ClassicPlusTimeline = lazy(() => import("./pages/classic-plus/timeline"));
const ClassicPlusPricing = lazy(() => import("./pages/classic-plus/pricing"));
const ClassicPlusHelpCenter = lazy(
    () => import("./pages/classic-plus/help-center")
);
const ClassicPlusInvoice = lazy(() => import("./pages/classic-plus/invoice"));
const ClassicPlusCalendar = lazy(
    () => import("./pages/classic-plus/apps/calendar")
);
const ClassicPlusChat = lazy(() => import("./pages/classic-plus/apps/chat"));
const ClassicPlusContacts = lazy(
    () => import("./pages/classic-plus/apps/contacts")
);
const ClassicPlusCustomers = lazy(
    () => import("./pages/classic-plus/apps/customers")
);
const ClassicPlusStamps = lazy(
    () => import("./pages/classic-plus/apps/stamps")
);

const ClassicPlusStampCreatEdit = lazy(
    () => import("./pages/classic-plus/apps/stamp-create-edit")
);
const ClassicPlusStampDetails = lazy(
    () => import("./pages/classic-plus/apps/stamp-details")
);
const ClassicPlusCustomersTypes = lazy(
    () => import("./pages/classic-plus/apps/customer-types")
);
const ClassicPlusCoeMeals = lazy(
    () => import("./pages/classic-plus/apps/coe-meals")
);
const ClassicPlusCurrencies = lazy(
    () => import("./pages/classic-plus/apps/currencies")
);
// const ClassicPlusMeals = lazy(() => import("./pages/classic-plus/apps/meals"));
const ClassicPlusMenu = lazy(() => import("./pages/classic-plus/apps/menu"));
const ClassicPlusMealTypes = lazy(
    () => import("./pages/classic-plus/apps/meal-types")
);
const ClassicPlusSubscription = lazy(
    () => import("./pages/classic-plus/apps/subscription")
);
const ClassicPlusPlaces = lazy(
    () => import("./pages/classic-plus/apps/places")
);
const ClassicPlusCoePlaces = lazy(
    () => import("./pages/classic-plus/apps/coe-places")
);
const ClassicPlusPlacesTypes = lazy(
    () => import("./pages/classic-plus/apps/places-types")
);
const ClassicPlusPlaceMedia = lazy(
    () => import("./pages/classic-plus/apps/place-media")
);
const ClassicPlusPlaceDetails = lazy(
    () => import("./pages/classic-plus/apps/places-details")
);
const ClassicPlusLogs = lazy(() => import("./pages/classic-plus/apps/logs"));
const ClassicPlusCities = lazy(
    () => import("./pages/classic-plus/apps/cities")
);
const ClassicPlusStates = lazy(
    () => import("./pages/classic-plus/apps/states")
);
const ClassicPlusFileManager = lazy(
    () => import("./pages/classic-plus/apps/file-manager")
);
const ClassicPlusUsers = lazy(() => import("./pages/classic-plus/apps/users"));
const ClassicPlusCountries = lazy(
    () => import("./pages/classic-plus/apps/countries")
);
const ClassicPlusMail = lazy(() => import("./pages/classic-plus/apps/mail"));

const App: React.FC = () => {
    return (
        <>
            <Router>
                <Suspense fallback={<Preloader />}>
                    <LoggedUserProvider>
                        <Routes>
                            {/* Classic Routes */}
                            {/* Dashboard Routes */}
                            <Route
                                path="/"
                                element={
                                    <Navigate to="/apps/scheduler" replace />
                                    // <RequireAuth>
                                    //     <ClassicPlusDashboardOne />
                                    // </RequireAuth>
                                }
                            />

                            <Route
                                path="/dashboard-two"
                                element={
                                    <RequireAuth>
                                        <DashboardTwo />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/dashboard-three"
                                element={
                                    <RequireAuth>
                                        <DashboardThree />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/dashboard-four"
                                element={
                                    <RequireAuth>
                                        <DashboardFour />
                                    </RequireAuth>
                                }
                            />
                            {/* Authentication Routes */}
                            {/* Se cambiaron las elements para así poder tener urls mas limpias */}
                            <Route
                                path="/signin"
                                element={<ClassicPlusSignIn />}
                            />
                            <Route
                                path="/claim-account"
                                element={<LandingPage />}
                            />
                            <Route
                                path="/signup"
                                element={<ClassicPlusSignUp />}
                            />
                            <Route
                                path="/create-account"
                                element={<RegisterV2 />}
                            />
                            <Route
                                path="/payment-stripe"
                                element={<ClassicPlusPaymentStripe />}
                            />
                            <Route
                                path="/free-premium"
                                element={<ClassicPlusFreeOrPremium />}
                            />
                            <Route
                                path="/verify-account"
                                element={<VerifyAccount />}
                            />
                            <Route
                                path="/password-reset/:token"
                                element={<ResetPassword />}
                            />
                            {/* Error Routes */}
                            <Route path="/error-500" element={<Error500 />} />
                            <Route path="/error-503" element={<Error503 />} />
                            <Route path="/error-505" element={<Error505 />} />

                            {/* User Routes */}
                            <Route
                                path="/profile-view"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusProfileView />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/connections"
                                element={
                                    <RequireAuth>
                                        <Connections />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/groups"
                                element={
                                    <RequireAuth>
                                        <Groups />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/events"
                                element={
                                    <RequireAuth>
                                        <Events />
                                    </RequireAuth>
                                }
                            />
                            {/* Other Routes */}
                            <Route
                                path="/timeline"
                                element={
                                    <RequireAuth>
                                        <Timeline />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pricing"
                                element={
                                    <RequireAuth>
                                        <Pricing />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/help-center"
                                element={
                                    <RequireAuth>
                                        <HelpCenter />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/invoice"
                                element={
                                    <RequireAuth>
                                        <Invoice />
                                    </RequireAuth>
                                }
                            />
                            {/* Apps Routes 
                                Roputes used in the TableFor proyect
                                */}
                            <Route
                                path="/apps/scheduler"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusCalendar />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/apps/chat"
                                element={
                                    <RequireAuth>
                                        <Chat />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/apps/contacts"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusContacts />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/customers"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusCustomers />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/customers-types"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusCustomersTypes />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/currencies"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusCurrencies />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/meals/coe/:id"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusCoeMeals />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/menu"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusMenu />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/meal-types"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusMealTypes />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/subscriptions"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusSubscription />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/places"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusPlaces />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/place-details/:id"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusPlaceDetails />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/places/coe/:id"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusCoePlaces />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/places-types"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusPlacesTypes />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/place-media"
                                element={<ClassicPlusPlaceMedia />}
                            />
                            <Route
                                path="/pages/logs"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusLogs />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/users"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusUsers />
                                    </RequireAuth>
                                }
                            />

                            <Route
                                path="/pages/countries"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusCountries />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/apps/mail"
                                element={
                                    <RequireAuth>
                                        <Mail />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/stamps"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusStamps />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/stamp/:id"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusStampCreatEdit />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/stamp/:id/details"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusStampDetails />
                                    </RequireAuth>
                                }
                            />

                            {/* Classic Plus Routes */}

                            {/* Dashboard Routes */}
                            <Route
                                path="/classic-plus/dashboard-one"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusDashboardOne />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/cities"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusCities />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/pages/states"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusStates />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/dashboard-two"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusDashboardTwo />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/dashboard-three"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusDashboardThree />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/dashboard-four"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusDashboardFour />
                                    </RequireAuth>
                                }
                            />
                            {/* Authentication Routes */}
                            <Route
                                path="/classic-plus/signin"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusSignIn />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/signup"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusSignUp />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/verify-account"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusVerifyAccount />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/forgot-password"
                                element={
                                    <RequireAuth>
                                        <ResetPassword />
                                    </RequireAuth>
                                }
                            />
                            {/* Error Routes */}
                            <Route
                                path="/classic-plus/error-500"
                                element={<ClassicPlusError500 />}
                            />
                            <Route
                                path="/classic-plus/error-503"
                                element={<ClassicPlusError503 />}
                            />
                            <Route
                                path="/classic-plus/error-505"
                                element={<ClassicPlusError505 />}
                            />
                            {/* User Routes */}
                            <Route
                                path="/classic-plus/profile-view"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusProfileView />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/connections"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusConnections />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/groups"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusGroups />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/events"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusEvents />
                                    </RequireAuth>
                                }
                            />
                            {/* Other Routes */}
                            <Route
                                path="/classic-plus/timeline"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusTimeline />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/pricing"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusPricing />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/help-center"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusHelpCenter />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/invoice"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusInvoice />
                                    </RequireAuth>
                                }
                            />
                            {/* Apps Routes */}
                            <Route
                                path="/classic-plus/apps/calendar"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusCalendar />
                                    </RequireAuth>
                                }
                            />

                            <Route
                                path="/classic-plus/apps/chat"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusChat />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/apps/contacts"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusContacts />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/apps/file-manager"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusFileManager />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/classic-plus/apps/mail"
                                element={
                                    <RequireAuth>
                                        <ClassicPlusMail />
                                    </RequireAuth>
                                }
                            />
                            {/* 404 Page Route */}
                            <Route path="*" element={<ErrorNotFound />} />
                            <Route
                                path="*"
                                element={<ClassicPlusErrorNotFound />}
                            />
                        </Routes>
                    </LoggedUserProvider>
                </Suspense>
            </Router>
        </>
    );
};

export default App;
