import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state

export interface ISchedule {
    id: number;
    uid: string;
    name: string;
    detail?: string;
    image_path: string;
    video_path?: string | null;
    fixed_price?: number;
    discount_rate?: number;
    place_id: number;
    meal: {
        uid: string;
        name: string;
        image_path?: string;
        meal_type?: {
            id: number;
            uid: string;
            name: string;
            published: boolean;
        };
        show: boolean;
        is_highlight: boolean;
        place_id: number;
    } | null;
    meal_type?: {
        id: number;
        uid: string;
        name: string;
        published: boolean;
    };
    show: boolean;
    schedule?: Array<{
        uid: string;
        valid_from: string;
        valid_until: string;
        ad_schedule_type: number;
        published: boolean;
    }>;
}

export interface ScheduleState {
    scheduleSources: ISchedule[];
}

// interface ScheduleData {
//     dataSchedulers: ISchedule[];
// }

// // Define the initial state using that type
const initialStat: ScheduleState = {
    scheduleSources: [],
};

const scheduleSlice = createSlice({
    name: "schedules",
    initialState: initialStat,
    reducers: {
        AddListSchedule: {
            reducer: (state, action: PayloadAction<ISchedule[]>) => {
                return {
                    scheduleSources: action.payload,
                };
            },
            prepare: (dataSchedulers: ISchedule[]) => {
                return {
                    payload: dataSchedulers,
                };
            },
        },
        createSchedule: {
            reducer: (state, action: PayloadAction<ISchedule>) => {
                state.scheduleSources.push(action.payload);
            },
            prepare: (value: ISchedule) => {
                return { payload: value };
            },
        },
        // editEvent: {
        //     reducer: (state, action: PayloadAction<IEventState>) => {
        //         const {
        //             payload: { category, ...data },
        //         } = action;
        //         const source = state.eventSources.find(
        //             (src) => src.category === category
        //         );
        //         if (source) {
        //             const index = source.events.findIndex(
        //                 (el) => el.id === data.id
        //             );
        //             if (index !== undefined && index >= 0) {
        //                 source.events[index] = data;
        //             }
        //         }
        //     },
        //     prepare: (
        //         id,
        //         title: string,
        //         startDate: string,
        //         endDate: string,
        //         category: string,
        //         startTime?: string,
        //         endTime?: string,
        //         description?: string
        //     ) => {
        //         const start = startTime
        //             ? `${startDate}T${startTime}:00`
        //             : startDate;
        //         const end = endTime ? `${endDate}T${endTime}:00` : endDate;
        //         const data = {
        //             category,
        //             id,
        //             title,
        //             start,
        //             end,
        //             description,
        //         };
        //         return { payload: { ...data } };
        //     },
        // },
        deleteSchedule: (state, action: PayloadAction<ISchedule>) => {
            const {
                payload: { id },
            } = action;

            const index = state.scheduleSources.findIndex((el) => el.id === id);
            if (index !== undefined && index >= 0) {
                state.scheduleSources.splice(index, 1);
            }
        },
    },
});

export const {
    AddListSchedule,
    createSchedule,
    deleteSchedule,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
