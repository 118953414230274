import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/redux/hooks";

export const loggedUserContext = React.createContext({} as any);

interface IContextProps {
    children: JSX.Element[] | JSX.Element;
}

export default function LoggedUserProvider({ children }: IContextProps): any {
    const [loggedUser, setLoggedUser] = useState(null);
    const [tokenUser, setTokenUser] = useState(null);
    const [isAddedCoe, setIsAddedCoe] = useState("");
    const [dataCustomers, setDataCustomers] = useState([]);
    const [dataCities, setDataCities] = useState([]);
    const [dataPlaceTypes, setDataPlaceTypes] = useState([]);
    const [userPayment, setUserPayment] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [calendarRefresh, setCalendarRefresh] = useState(false);
    const [socialType, setSocialType] = useState<
        "Facebook" | "Instagram" | "Pix" | "All"
    >("All");
    const token = useAppSelector((state) => state.auth.credential.access_token);
    const redirectRoute = useNavigate();
    useEffect(() => {
        if (token === undefined) {
            // redirectRoute("/signin");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);
    
    return (
        <loggedUserContext.Provider
            value={{
                loggedUser,
                setLoggedUser,
                tokenUser,
                setTokenUser,
                isAddedCoe,
                setIsAddedCoe,
                dataCustomers,
                setDataCustomers,
                dataCities,
                setDataCities,
                dataPlaceTypes,
                setDataPlaceTypes,
                refresh,
                setRefresh,
                socialType,
                setSocialType,
                calendarRefresh,
                setCalendarRefresh,
                userPayment,
                setUserPayment,
            }}
        >
            {children}
        </loggedUserContext.Provider>
    );
}
