import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

// Define a type for the slice state

export interface ISpost {
    id: number;
    uid: string;
    name: string;
    detail?: string;
    image_path: string;
    video_path?: string | null;
    place_id: number;
    social_type: number;
    status: number;
    created_by: number;
    updated_by: number;
    overlay_text?: string;
    scheduled_for: string;
}

export interface PostState {
    postSources: ISpost[];
}

// interface ScheduleData {
//     dataSchedulers: ISchedule[];
// }

// // Define the initial state using that type
const initialStat: PostState = {
    postSources: [],
};

const postSlice = createSlice({
    name: "posts",
    initialState: initialStat,
    reducers: {
        AddListPosts: {
            reducer: (state, action: PayloadAction<ISpost[]>) => {
                return {
                    postSources: action.payload,
                };
            },
            prepare: (dataPosts: ISpost[]) => {
                return {
                    payload: dataPosts,
                };
            },
        },
        createPost: {
            reducer: (state, action: PayloadAction<ISpost>) => {
                state.postSources.push({...action.payload, scheduled_for: moment(action?.payload?.scheduled_for.replace(".000000Z", "")).format("YYYY-MM-DD HH:mm:ss")});
            },
            prepare: (value: ISpost) => {
                return { payload: value };
            },
        },
        editPost: {
            reducer: (state, action: PayloadAction<ISpost>) => {
                const index = state.postSources.findIndex(
                    (post) => post.id === action.payload.id
                );
                state.postSources[index] = {...action.payload, scheduled_for: moment(action?.payload?.scheduled_for.replace(".000000Z", "")).format("YYYY-MM-DD HH:mm:ss")};
            },
            prepare: (value: ISpost) => {
                return { payload: value };
            },
        },
        deletePost: (state, action: PayloadAction<ISpost>) => {
            const {
                payload: { id },
            } = action;

            const index = state.postSources.findIndex((el) => el.id === id);
            if (index !== undefined && index >= 0) {
                state.postSources.splice(index, 1);
            }
        },
    },
});

export const {
    AddListPosts,
    createPost,
    deletePost,
    editPost,
} = postSlice.actions;

export default postSlice.reducer;
