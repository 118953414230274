import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import eventReducer from "./slices/event";
import uiReducer from "./slices/ui";
import chatUISlice from "./slices/chat-ui";
import scheduleSlice from "./slices/schedule";
import mealSlice from "./slices/meals";
import authSlice from "./slices/auth";
import mealTypeSlice from "./slices/mealtypes";
import placeSlice from "./slices/places";
import userSlice from "./slices/user";
import storySlice from "./slices/story";
import postSlice from "./slices/post";
import carouselSlice from "./slices/carousel";
import CitiesSlice from "./slices/cities";

const persistConfig = {
    key: "doar",
    version: 1,
    storage,
    whitelist: [
        "ui",
        "chatUI",
        "auth",
        "meals",
        "cities",
        "schedules",
        "user",
        "places",
        "meals",
        "mealTypes",
        "stories",
        "posts",
        "carousel",
    ],
};

export const rootReducer = combineReducers({
    events: eventReducer,
    ui: uiReducer,
    chatUI: chatUISlice,
    schedules: scheduleSlice,
    meals: mealSlice,
    auth: authSlice,
    cities: CitiesSlice,
    mealTypes: mealTypeSlice,
    places: placeSlice,
    user: userSlice,
    stories: storySlice,
    posts: postSlice,
    carousel: carouselSlice,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//     reducer: rootReducer,
// });

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: { extraArgument: {} },
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
});

// export const store = createStore(persistedReducer, applyMiddleware(thunk));

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
