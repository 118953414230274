import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ISimage {
    id: number;
    image_path: string;
    overlay_text: string;
    place_id: number;
    place_story_id: number;
    uid: string;
    videro_path: string;
}
export interface IStory {
    id: string;
    uid?: string;
    name?: string;
    detail?: string;
    place_id?: number;
    social_type?: number;
    status?: number;
    created_by?: number;
    updated_by?: number;
    medias?: Array<ISimage>;
    video_path?: string;
    scheduler?: {
        id: number;
        place_id: number;
        place_story_id: number;
        scheduled_for: string;
        scheduled_until: string;
        uid: string;
    };
    story_type: number;
}

export interface StoryState {
    storySources: IStory[];
}

const initialState: StoryState = {
    storySources: [],
};

const StorySlice = createSlice({
    name: "Stories",
    initialState,
    reducers: {
        AddListStories: {
            reducer: (state, action: PayloadAction<IStory[]>) => {
                return {
                    storySources: action.payload,
                };
            },
            prepare: (dataStories: IStory[]) => {
                return {
                    payload: dataStories,
                };
            },
        },
        createStory: {
            reducer: (state, action: PayloadAction<IStory>) => {
                state.storySources.push(action.payload);
            },
            prepare: (value: IStory) => {
                return { payload: value };
            },
        },
        editStory: {
            reducer: (state, action: PayloadAction<IStory>) => {
                const index = state.storySources.findIndex(
                    (story) => story.id === action.payload.id
                );
                state.storySources[index] = action.payload; // eslint-disable-line no-param-reassign
            },
            prepare: (value: IStory) => {
                return { payload: value };
            },
        },
        deleteStory: (state, action: PayloadAction<IStory>) => {
            const {
                payload: { id },
            } = action;

            const index = state.storySources.findIndex((el) => el.id === id);

            if (index !== undefined && index >= 0) {
                state.storySources.splice(index, 1);
            }
        },
    },
});

export const {
    AddListStories,
    deleteStory,
    createStory,
    editStory,
} = StorySlice.actions;

export default StorySlice.reducer;
