import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state

export interface IMeal {
    id: number;
    uid: string;
    name: string;
    image_path?: string;
    meal_type_id: number;
    show: boolean;
    is_highlight: boolean;
    place_id: number;
    published: number;
}

export interface MealState {
    mealSources: IMeal[];
}

// // Define the initial state using that type
const initialStat: MealState = {
    mealSources: [],
};

const mealSlice = createSlice({
    name: "meals",
    initialState: initialStat,
    reducers: {
        AddListMeals: {
            reducer: (state, action: PayloadAction<IMeal[]>) => {
                return {
                    mealSources: action.payload,
                };
            },
            prepare: (data: IMeal[]) => {
                return {
                    payload: data,
                };
            },
        },
    },
});

export const { AddListMeals } = mealSlice.actions;

export default mealSlice.reducer;
