import { css } from "styled-components";
import PoppinsWoff from "./poppins-sans/woff/Poppins.woff";
import PoppinsWoff2 from "./poppins-sans/woff2/Poppins.woff2";

import PoppinsBlackWoff from "./poppins-sans/woff/Poppins-Black.woff";
import PoppinsBlackWoff2 from "./poppins-sans/woff2/Poppins-Black.woff2";

import PoppinsBoldWoff from "./poppins-sans/woff/Poppins-Bold.woff";
import PoppinsBoldWoff2 from "./poppins-sans/woff2/Poppins-Bold.woff2";

import PoppinsLigthWoff from "./poppins-sans/woff/Poppins-Light.woff";
import PoppinsLigthWoff2 from "./poppins-sans/woff2/Poppins-Light.woff2";

import PoppinsThinWoff from "./poppins-sans/woff/Poppins-Thin.woff";
import PoppinsThinWoff2 from "./poppins-sans/woff2/Poppins-Thin.woff2";

export const Poppins = css`
    @font-face {
        font-family: "Poppins";
        font-display: swap;
        font-style: normal;
        font-weight: normal;
        src: url(${PoppinsWoff}) format("woff"),
            url(${PoppinsWoff2}) format("woff2");
    }

    @font-face {
        font-family: "Poppins";
        font-display: swap;
        font-style: normal;
        font-weight: 800;
        src: url(${PoppinsBlackWoff}) format("woff"),
            url(${PoppinsBlackWoff2}) format("woff2");
    }

    @font-face {
        font-family: "Poppins";
        font-display: swap;
        font-style: normal;
        font-weight: bold;
        src: url(${PoppinsBoldWoff}) format("woff"),
            url(${PoppinsBoldWoff2}) format("woff2");
    }

    @font-face {
        font-family: "Poppins";
        font-display: swap;
        font-style: normal;
        font-weight: 500;
        src: url(${PoppinsLigthWoff}) format("woff"),
            url(${PoppinsLigthWoff2}) format("woff2");
    }

    @font-face {
        font-family: "Poppins";
        font-display: swap;
        font-style: normal;
        font-weight: 100;
        src: url(${PoppinsThinWoff}) format("woff"),
            url(${PoppinsThinWoff2}) format("woff2");
    }
`;
